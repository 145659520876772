import React, { Component } from 'react'
import theme from '$styles/_theme.scss'

class CategoryImage extends Component {
    render () {
        const isMobile = document.body.clientWidth < parseInt(theme.lg.replace('px', ''))
        const { categoryName } = this.props
        const categoryNameWithExtension = isMobile ? categoryName + '_mobile.jpg' : categoryName + '.jpg'
        const imgHeight = isMobile ? '180px' : '400px'

        return (
            <div className="main-img" style={{ width: '100%', height: imgHeight, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: `url('https://pelprint.pelhughes.com/ustorethemes/PelPrint/assets/images/${categoryNameWithExtension}')` }} />
        )
    }
}

export default CategoryImage
